.timerWrapper {
  width: 100vw;
  height: 100vh;
  background-color: rgba(18, 18, 18, 0.5);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  z-index: 999;
  top: 0;
  left: 0;
  backdrop-filter: blur(30px) grayscale(100%);
  /* border: solid red 2px; */
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
}

.timer-header {
  color: #fff;
  text-transform: capitalize;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countDownItems {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Days One", sans-serif;
  font-weight: 800;
  font-size: 3.25rem;
  padding: 2rem;
  border-radius: 0.25rem;
  text-decoration: none;
  color: var(--color-light);
  background-color: var(--yellow);
  width: 30rem;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 1rem;
}

/* Layout container */
.layout-container {
  position: relative;
  min-height: 100vh;
}

.main-content {
  position: relative;
  z-index: 1;
}
